import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';

let aseKeyNew = "abcdef0123456789";

let RSAPublicKey="MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRqL/ke4gle36pZNZf5h+g0ZOZSoCaeyENgS6OUUptGTO8qfaqwpmqiI/5rpyrH+Lj5EdIzm9ABbxgiDuAPjuyBkmgDzgEG9IQQ3nv1Jo3WWpRwGngv2yXmkq6lHf+5ZEAickg2JPE4xvD7iAHViBmEE6BgNgxTmpflcp7Gdl1YwIDAQAB"
let RSAPrivateKey="MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAJGov+R7iCV7fqlk1l/mH6DRk5lKgJp7IQ2BLo5RSm0ZM7yp9qrCmaqIj/munKsf4uPkR0jOb0AFvGCIO4A+O7IGSaAPOAQb0hBDee/UmjdZalHAaeC/bJeaSrqUd/7lkQCJySDYk8TjG8PuIAdWIGYQToGA2DFOal+VynsZ2XVjAgMBAAECgYAvAcSb2foADrC7vh0o6b0/SyNVDyQdtqreDtiVeUqJlt6Jflhql068KEI+k8lfuHSikyMmLejdQnyesphxNKRSupi7JfcMpabTd4nbLbjeO32Dk0CbtvS9E0uEPDsAasvjQlb+p/ZAXy0u0XpFXgAuPbAblukxB49xCo4mx8HCQQJBAOo8al2cYTfpkJlmMdKoZcaJlAjwZll6PrnBfSkWumnaycWtW0kxT84vl1WNEZ73f6T8aLjO8htpK7iPQINs1vECQQCfMW2U60X4qS5ejmVKTUb0RRg+1/l+5lC+J0zFOafDSo9jkFJFFELnx6slymLRW7vpV/CPPA9tfFFAtkMcJJmTAkBaDZgnHTQTNuyszSlif/OA/Ou49BOlWuY6rDTeUyqf/8mLAjcSUK8J6ioYbf/USfr9Bz2Lr6RM/YYdkpWO8Y1BAkEAiC4hXtZRGiGn4A2SCpjGzOlaj0/0R6FAixpVaxYiNEbRGMv6BMwLW/mLqB1UoWIHAttOl0k6rV8+pswD5sxMbwJAZzfXCmUymI7i4sv9WWARg75YysnVmeW/jUMH2GopGprl1+Jmfc86LLaKIfqJfVwqIqPeES+nxvEb2LLahvfghw==";

//RSA加密
export function encryptRSA() {
  var jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(RSAPublicKey);
  return jsencrypt.encrypt(aseKeyNew);
}

//RSA解密
export function decryptRSA(key) {
  var decrypt = new JSEncrypt();
  decrypt.setPrivateKey(RSAPrivateKey);
  return decrypt.decrypt(key);
}

//AES加密
export function encryptAES(data) {
  var key = CryptoJS.enc.Utf8.parse(aseKeyNew);//将秘钥转换成Utf8字节数组

  //加密
  var encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    //iv: CryptoJS.enc.Utf8.parse(aseKey.substr(0,16)),
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  var data = encrypt.toString();//加密后的数据
  return data;
}

//AES解密
export function decryptAES(data, key) {
  var data = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8);
  return data;
}

